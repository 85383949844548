import styled from 'styled-components';

export const ErrorGrid = styled.div`
  display: flex;
  height: calc(100vh - 192px);
  width: calc(100vw - 192px);
  flex-direction: row;
  align-items: center;
  margin: 96px;
  justify-content: space-between;
  box-sizing: border-box;

  .error-message {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }
  .error-image {
    display: flex;
    align-self: flex-end;
  }
`;
